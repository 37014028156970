import React from "react";
import { compose, withHandlers, withState } from "recompose";
import scrollToTop from "utils/scrollToTop";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";
import createList from "./createList";
import deserialize from "utils/deserialize";
import serializer from "components/TextEditor/editorSerializer";

const UPSERT_ASSESSMENT_SUMMARY = loader("./upsertAssessmentSummary.graphql");

export default compose(
  withRouter,

  handleLoading(() => <FullPage />),
  withState(
    "list",
    "setList",
    ({ person, evaluations, notes, viewer, assessmentRows }) =>
      createList({ person, evaluations, notes, viewer, assessmentRows })
  ),
  withState("executiveSummary", "setExecutiveSummary", ({ assessment }) =>
    assessment.summary ? deserialize(assessment.summary) : deserialize("")
  ),
  withState("saved", "setSaved", false),
  graphql(UPSERT_ASSESSMENT_SUMMARY, {
    name: "upsertAssessmentSummary",
  }),

  withHandlers({
    handleRemoveRating:
      ({ removeEvaluation }) =>
      async ({ id }) => {
        await removeEvaluation({
          variables: {
            id,
          },
        });
      },
    handleSaveRating:
      ({
        saveEvaluation,
        match: {
          params: { candidateId, positionId, candidateType },
        },
      }) =>
      async ({ evaluation_type, item_id, evaluation }) => {
        await saveEvaluation({
          variables: {
            cand_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,

            pos_id: parseInt(positionId, 10),
            evaluation_type,
            item_id,
            evaluation,
          },
        });
      },
    handleRemoveComment:
      ({ removeComment }) =>
      async (comment) => {
        await removeComment({
          variables: {
            id: comment.id,
          },
        });
      },
    handleUpsertAssessmentSummary:
      ({
        upsertAssessmentSummary,
        assessment,
        assessmentScorecard,
        saved,
        setSaved,
      }) =>
      async (summary) => {
        await upsertAssessmentSummary({
          variables: {
            assessment_id: assessment.id,
            summary: serializer.serialize(summary),
          },
        });
        setSaved(true);
        setTimeout(() => setSaved(false), 4000);
      },
  }),
  scrollToTop
);
