import { compose, withHandlers } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import {
  CLOSE_INVITE,
  SELECT_ORG,
  SET_ACCOUNT,
  SET_VIEWER,
} from "store/actionTypes";
import withStore from "utils/withStore";
import { withRouter } from "react-router-dom";

const DECLINE_INVITE = loader("client/Mutations/DeclineInvite1.graphql");
const ACCEPT_INVITE = loader("client/Mutations/AcceptInvite5.graphql");
const INVITES = loader("client/Queries/Invites7.graphql");
const ORGANIZATIONS = loader("client/Queries/OrganizationsQuery22.graphql");
const VIEWER = loader("client/Queries/ViewerQuery25.graphql");

export default compose(
  withRouter,
  withApollo,
  withStore("inviteStore"),
  graphql(DECLINE_INVITE, {
    name: "declineInviteMutation",
    options: (props) => {
      return {
        update: (proxy) => {
          const data = proxy.readQuery({
            query: INVITES,
          });
          data.invites = data.invites.filter(
            (invite) => invite.id !== props.invite.id
          );
          proxy.writeQuery({
            query: INVITES,
            data,
          });
        },
      };
    },
  }),
  graphql(ACCEPT_INVITE, {
    name: "acceptInviteMutation",
    options: (props) => {
      return {
        update: (proxy, { data: { acceptInvite } }) => {
          const data = proxy.readQuery({
            query: INVITES,
          });
          data.invites = data.invites.filter(
            (invite) => invite.id !== props.invite.id
          );
          proxy.writeQuery({
            query: INVITES,
            data,
          });

          const viewer = proxy.readQuery({
            query: VIEWER,
          });

          viewer.viewer.accounts.push({
            id: acceptInvite.account.id,
            name: acceptInvite.account.name,
            role: acceptInvite.account.adminRights ? "admin" : null,
            __typename: `ViewerAccount:${acceptInvite.account.id}`,
          });

          viewer.default_account_id = acceptInvite.account.id;

          proxy.writeQuery({
            query: VIEWER,
            data: viewer,
          });

          props.client.query({
            query: ORGANIZATIONS,
            variables: {
              account_id: acceptInvite.account.id,
            },
            fetchPolicy: "network-only",
          });

          props.dispatch({
            type: SET_ACCOUNT,
            payload: {
              id: acceptInvite.account.id,
              name: acceptInvite.account.name,
              role: acceptInvite.account.adminRights ? "admin" : null,
            },
          });

          props.dispatch({
            type: SET_VIEWER,
            payload: viewer.viewer,
          });
        },
      };
    },
  }),
  withHandlers({
    acceptInvite:
      ({ invite, acceptInviteMutation, dispatch, history }) =>
      () => {
        acceptInviteMutation({
          variables: {
            inviteId: invite.id,
          },
        }).then((res) => {
          // console.log({ res })
          dispatch({
            type: SELECT_ORG,
            payload: res.data.acceptInvite,
          });
          dispatch({
            type: SET_ACCOUNT,
            payload: res.data.acceptInvite.account,
          });
          dispatch({ type: CLOSE_INVITE });
          history.push("/dashboard");
        });
      },
    declineInvite:
      ({ invite, declineInviteMutation, dispatch }) =>
      () => {
        declineInviteMutation({
          variables: {
            inviteId: invite.id,
          },
        }).then((res) => {
          dispatch({ type: CLOSE_INVITE });
        });
      },
  })
);
