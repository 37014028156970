import React, { useState, useEffect } from "react";
import classNames from "classnames";
import DOMPurify from "dompurify";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import WisGPTInsights from "./WisGPTInsights";
import Scoring from "./Scoring";
import Comments from "./Comments";
import compose from "./compose";
import { v4 as uuidv4 } from "uuid";
import serializer from "components/TextEditor/editorSerializer";
import styles from "./Step.module.scss";

const getContentHtml = (content) => ({
  __html: DOMPurify.sanitize(content),
});

export default compose(
  ({
    dim,
    assessment,
    steps,
    handleSaveRating,
    handleSaveSpanRating,
    currentStep,
    handleStep,
    wisgptContextToken,
    person,
    editNote,
    setEditNote,
    note,
    setNote,
    viewer,
    collapsed,
    setCollapsed,
    upsertNote,
  }) => {
    const [readMore, setReadMore] = useState(false);
    const [editorValue, setEditorValue] = useState(
      serializer.deserialize(note)
    );
    const [conversationId, setConversationId] = useState(uuidv4());

    const dimDescription = getContentHtml(dim.description);

    return (
      <div className={styles.step}>
        <div className={styles.left}>
          <div className={styles.topRow}>
            <h5 className={styles.groupTitle}>
              <span className={classNames(styles.icon, styles[dim.color])}>
                <Icon icon={dim.icon} />
              </span>
              {dim.group_title}
            </h5>
            <div className={styles.evaluation}>
              <Scoring
                item={dim}
                handleSaveRating={handleSaveRating}
                handleSaveSpanRating={handleSaveSpanRating}
              />
            </div>
          </div>
          <div className={styles.dimContent}>
            <p className={styles.dimTitle}>{dim.title}</p>
            <div
              className={classNames(
                styles.dimDescription,
                readMore && styles.full,
                dim.description.length > 220 && styles.long
              )}
              dangerouslySetInnerHTML={dimDescription}
            ></div>
            {dim.description.length > 220 && (
              <div
                className={classNames(
                  styles.readMoreWrapper,
                  readMore && styles.opened
                )}
              >
                <Button
                  bStyle={["none"]}
                  type="button"
                  onClick={() => setReadMore(!readMore)}
                >
                  {readMore ? (
                    <span className={styles.open}>
                      Read less <Icon icon="Chevron" />
                    </span>
                  ) : (
                    <span>
                      Read more <Icon icon="Chevron" />
                    </span>
                  )}
                </Button>
              </div>
            )}
          </div>
          <div className={styles.bottom}>
            <div>
              <h4>Notes</h4>
              <p className={styles.subTitle}>
                Additional notes and comments about this candidate
              </p>
            </div>
            <div className={styles.progress}>
              <p className={styles.text}>{`${currentStep + 1} of ${
                steps.length
              }`}</p>
              <div className={styles.bar}>
                <div
                  className={styles.filler}
                  style={{
                    width: `${((currentStep + 1) / steps.length) * 100}%`,
                  }}
                ></div>
              </div>
            </div>

            <div className={styles.buttons}>
              <Button
                bStyle={["newsimple", "verySmall"]}
                onClick={() => handleStep("prev", currentStep)}
                disabled={currentStep === 0}
              >
                <span className={classNames(styles.arrow, styles.reverse)}>
                  <Icon icon="Arrow" />
                </span>
                Back
              </Button>
              <Button
                bStyle={["primary", "verySmall"]}
                onClick={() => handleStep("next", currentStep)}
              >
                {currentStep === steps.length - 1 ? "Finish" : "Next"}
                <span className={styles.arrow}>
                  <Icon icon="Arrow" />
                </span>
              </Button>
            </div>
          </div>
          <div className={styles.notes}>
            <Comments
              note={note}
              setNote={setNote}
              editorValue={editorValue}
              setEditorValue={setEditorValue}
              upsertNote={upsertNote}
              editNote={editNote}
              setEditNote={setEditNote}
              notes={dim.notes}
              row={dim}
              viewer={viewer}
              assessment={assessment}
              person={person}
            />
          </div>
        </div>
        <div className={styles.right}>
          <WisGPTInsights
            setEditor={(note) => {
              setEditorValue(serializer.deserialize(note));
            }}
            conversationId={conversationId}
            wisgptContextToken={wisgptContextToken}
            prompt={steps[currentStep].title}
            assessmentRowId={steps[currentStep].id}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            examplePrompts={steps[currentStep].examplePrompts}
          />
        </div>
      </div>
    );
  }
);
