import React from "react";
import { compose, renderComponent, branch } from "recompose";
import { loader } from "graphql.macro";
import { graphql } from "react-apollo";
import FullPage from "components/Loaders/FullPage";
import handleLoading from "utils/loader";
import withStore from "utils/withStore";
import { withRouter } from "react-router-dom";

const ASSESSMENTS = loader("./assessments.graphql");
const DELETE_ASSESSMENT = loader("./deleteAssessment.graphql");

export default compose(
  withRouter,

  graphql(ASSESSMENTS, {
    options: ({
      match: {
        params: { account_id },
      },
    }) => {
      return {
        variables: {
          account_id: parseInt(account_id, 10),
        },
      };
    },
  }),
  graphql(DELETE_ASSESSMENT, {
    name: "deleteAssessment",
    options: ({
      data,
      match: {
        params: { account_id },
      },
    }) => {
      return {
        update: () => {
          data.refetch();
        },
      };
    },
  }),
  handleLoading(() => <FullPage />)
);
