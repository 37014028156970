import React from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import Timestamp from "react-timestamp";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import { Form } from "react-final-form";
import Icon from "components/UI/Icon";
import { printoutRoute } from "client/authRoutes";
import EllipsisButton from "components/UI/EllipsisButton";
import DropDown from "components/UI/DropDown";
import { ReactComponent as WhiteLogo } from "images/white-logo.svg";
import FormInfo from "components/PositionTemplates/UseTemplatePopup";

import styles from "./Template.module.scss";

import Chip from "../../Chip/index";

export const CategoryColors = {
  Leadership: "purple",
  Management: "primary",
  "People and talent": "yellow",
  Operations: "red",
  Sales: "green",
  Marketing: "blue",
};

export default ({
  openModal,
  template,
  view = "default",
  sessionData,
  setSessionData,
  handleClick,
}) => {
  return (
    <div
      className={classNames(
        styles.template,
        !template.isGlobal && styles.personal,
        styles[view]
      )}
      onClick={() => {
        handleClick(template);
        if (setSessionData) {
          setSessionData({ ...sessionData, scorecard_id: template.id });
        }
      }}
    >
      <div
        className={classNames(
          styles.buttonWrapper,
          template.isGlobal && styles.global
        )}
        style={{ position: "relative" }}
      >
        <div className={styles.background}>
          <WhiteLogo />
        </div>
        <div></div>
        <Button bStyle={["none"]}>
          <div className={styles.topAndMiddle}>
            <div className={styles.top}>
              {template.category && (
                <Chip color={CategoryColors[template.category]}>
                  {template.category}
                </Chip>
              )}
              {template.stage && (
                <Chip
                  color={CategoryColors[template.category]}
                  variant="outlined"
                >
                  {template.stage}
                </Chip>
              )}
            </div>
            <div
              className={classNames(
                styles.middle,
                !template.category && styles.noCategory
              )}
            >
              <h4 className={styles.name}>{template.title}</h4>
            </div>
          </div>
          <div className={styles.bottom}>
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <div className={styles.avatar}>
                {/* <UserAvatar member={template.createdBy} size="small" /> */}
              </div>
              <div className={styles.info}>
                <h5 className={styles.name}>{`${template.created_by}`}</h5>
              </div>
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
};
