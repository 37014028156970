import { withHandlers, compose } from "recompose";
import { loader } from "graphql.macro";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/handleLoading";

const SCORECARDS = loader("./scorecards.graphql");
const CREATE_ASSESSMENT = loader("./createAssessment.graphql");
const ASSESSMENTS = loader("../assessments.graphql");

export default compose(
  withRouter,
  graphql(SCORECARDS, {
    options: ({
      match: {
        params: { account_id },
      },
    }) => ({
      variables: {
        account_id: parseInt(account_id, 10),
      },
    }),
  }),
  graphql(CREATE_ASSESSMENT, {
    name: "createAssessment",
    options: ({
      match: {
        params: { account_id },
      },
    }) => {
      return {
        update: (proxy, { data: { createAssessment } }) => {
          const data = proxy.readQuery({
            query: ASSESSMENTS,
            variables: {
              account_id: parseInt(account_id, 10),
            },
          });

          data.assessments.push(createAssessment);

          proxy.writeQuery({
            query: ASSESSMENTS,
            variables: {
              account_id: parseInt(account_id, 10),
            },
            data,
          });
        },
      };
    },
  }),
  handleLoading
);
