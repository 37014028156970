import {
  compose,
  withProps,
  lifecycle,
  withState,
  withHandlers,
  branch,
  renderComponent,
} from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import withStore from "utils/withStore";
import QueryString from "query-string";
import { INVITE } from "store/actionTypes";
import scrollToTop from "utils/scrollToTop";
import purgeTeamFromCache from "utils/purgeTeamFromCache";

const INVITES = loader("client/Queries/Invites7.graphql");
const ORGANIZATION_TEAMS_AND_POSITIONS = loader(
  "client/Queries/OrganizationTeamsAndPositions.graphql"
);
const LEARNING_CENTER = loader("./learningCenter.graphql");
const CREATE_POSITION_MUTATION = loader(
  "client/Mutations/createPosition6.graphql"
);

export default compose(
  withRouter,
  withStore("inviteStore", "viewer", "selectedOrg", "account"),
  withState("modal", "updateModal", ""),
  withState("selectedTeam", "setSelectedTeam", -1),
  graphql(INVITES, { name: "INVITES_QUERY" }),
  graphql(ORGANIZATION_TEAMS_AND_POSITIONS, {
    name: "TEAMS",
    options: ({ selectedOrg }) => ({
      variables: { id: selectedOrg.id },
    }),
  }),
  graphql(LEARNING_CENTER, {
    name: "LEARNING_CENTER_QUERY",
  }),
  branch(
    (props) =>
      (props.INVITES_QUERY && props.INVITES_QUERY.loading) ||
      (props.LEARNING_CENTER_QUERY && props.LEARNING_CENTER_QUERY.loading),
    renderComponent(() => null)
  ),
  graphql(CREATE_POSITION_MUTATION, {
    name: "createPosition",
    options: ({ data, client, selectedOrg }) => ({
      update: (proxy, { data: { createNewPosition } }) => {
        const positions = proxy.readQuery({
          query: ORGANIZATION_TEAMS_AND_POSITIONS,
          variables: {
            id: selectedOrg.id,
          },
        });

        if (positions.organization && positions.organization.allOpenPositions) {
          positions.organization.allOpenPositions.push(createNewPosition);
          proxy.writeQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: createNewPosition.parent_org_id,
            },
            data: positions,
          });
        }

        if (
          proxy.data.data[
            `${createNewPosition.parentTeam.__typename}:${createNewPosition.parentTeam.id}`
          ]
        ) {
          purgeTeamFromCache({
            cache: client.cache.data,
            team_id: createNewPosition.parentTeam.id,
            proxy,
          });
        }
      },
    }),
  }),
  lifecycle({
    componentWillMount() {
      const params = queryString.parse(this.props.location.search);
      if (params && params.invite) {
        const validInvite = this.props.INVITES_QUERY.invites.find(
          (invite) => invite.secret === params.invite
        );
        if (validInvite) {
          this.props.dispatch({ type: INVITE, payload: validInvite });
        } else {
          window.history.pushState(
            {},
            "",
            `${window.location.origin}${window.location.pathname}`
          );
        }
      }
    },
  }),
  withProps((props) => {
    const queryString = QueryString.parse(props.location.search);
    return {
      showPositionTemplate: queryString.positionTemplate,
    };
  }),
  withHandlers({
    handleCreatePosition:
      ({ selectedOrg, selectedTeam, createPosition, history }) =>
      (e) => {
        createPosition({
          variables: {
            name: e.name,
            parent_org: selectedOrg.id,
            team_id: parseInt(selectedTeam, 10),
            template_id: e.template || null,
          },
        }).then((res) => {
          if (window.Intercom) {
            window.Intercom("trackEvent", "create-position", {
              id: res.data.createNewPosition.id,
            });
          }
          history.push(
            `/team/${selectedTeam}/position/${res.data.createNewPosition.id}`
          );
        });
      },
  }),
  scrollToTop
);
