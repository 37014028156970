import { compose, branch, withState, renderNothing } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";
import { withRouter } from "react-router-dom";
import scrollToTop from "utils/scrollToTop";

const UPDATE_ORG = loader("./UpdateOrg1.graphql");
const ORGANIZATIONS = loader("client/Queries/OrganizationsQuery22.graphql");
const DELETE_ORG = loader("./DeleteOrg.graphql");
const ORGANIZATION_QUERY = loader("./OrganizationQuery.graphql");
const TALENT_POOL_QUERY = loader("client/Queries/TalentPoolQuery9.graphql");

export default compose(
  withRouter,
  withApollo,
  withStore("selectedOrg", "viewer"),
  withState("role", "setRole", null),
  withState("limit", "setLimit", 15),
  withState("pageNr", "setPageNr", 0),
  withState("order", "setOrder", {
    column: "email",
    direction: "ASC",
  }),
  graphql(ORGANIZATION_QUERY, {
    name: "ORGANIZATION_QUERY",
    options: (props) => {
      return {
        variables: {
          id: parseInt(props.match.params.orgId, 10),
        },
      };
    },
  }),
  graphql(TALENT_POOL_QUERY, {
    name: "TALENT_POOL_QUERY",
    options: (props) => {
      return {
        variables: {
          id: parseInt(props.match.params.orgId, 10),
          offset: 0,
          limit: 15,
          orderDirection: "ASC",
          orderColumn: "email",
          role: props.role,
        },
      };
    },
  }),
  branch((props) => {
    const condition =
      (props.ORGANIZATION_QUERY && props.ORGANIZATION_QUERY.loading) ||
      (props.TALENT_POOL_QUERY && props.TALENT_POOL_QUERY.loading);
    return condition;
  }, renderNothing),
  graphql(DELETE_ORG, {
    name: "deleteOrg",
    options: ({ resetOrgSelect }) => {
      return {
        update: (proxy, { data: { deleteOrg } }) => {
          const data = proxy.readQuery({
            query: ORGANIZATIONS,
          });

          data.organizations = data.organizations.filter(
            (o) => o.id !== deleteOrg.id
          );

          proxy.writeQuery({
            query: ORGANIZATIONS,
            data,
          });
          window.localStorage.removeItem("recentOrg");
          resetOrgSelect();
        },
      };
    },
  }),
  graphql(UPDATE_ORG, {
    name: "updateOrg",
    options: ({ setSelectedOption }) => {
      return {
        update: (proxy, { data: { updateOrg } }) => {
          const data = proxy.readQuery({
            query: ORGANIZATIONS,
          });
          data.organizations.find((o) => o.id === updateOrg.id).name =
            updateOrg.name;
          proxy.writeQuery({
            query: ORGANIZATIONS,
            data,
          });
          setSelectedOption(updateOrg.name);
        },
      };
    },
  }),
  withState("memberModal", "updateMemberModal", ""),
  withState("overlayClose", "setOverlayClose", true),
  withState("inviteModal", "updateInviteModal", false),
  scrollToTop
);
