import React, { useState, useEffect } from "react";

import Step from "./Step";
import compose from "./compose";

import styles from "./EvaluationSteps.module.scss";

export default compose(
  ({
    WISGPT_CONTEXT_TOKEN: { wisgptContextToken },
    assessment,
    steps,
    currentTab,
    setCurrentTab,
    assessmentRows,
    setSteps,
    person,
    viewer,
    collapsed,
    setCollapsed,
    setContent,
    getLeadershipSpanEvaluation,
  }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const dim = steps[currentStep];

    const handleStep = (direction, currentStep) => {
      if (direction === "next") {
        if (steps.length - 1 >= currentStep + 1) {
          setCurrentStep(currentStep + 1);
        } else {
          setCurrentTab(currentTab + 1);
        }
      }

      if (direction === "prev") {
        if (currentStep - 1 >= 0) {
          setCurrentStep(currentStep - 1);
        }
      }
    };

    const listItems = [];
    const listSteps = [];

    steps.map((item, index) => {
      if (listSteps.some((ls) => ls.groupTitle === item.group_title)) {
        listSteps
          .find((ls) => ls.groupTitle === item.group_title)
          .items.push({
            title: item.title,
            action: () => setCurrentStep(index),
            style: currentStep === index ? "active" : "",
          });
      } else {
        listSteps.push({
          groupTitle: item.group_title,
          items: [
            {
              title: item.title,
              action: () => setCurrentStep(index),
              style: currentStep === index ? "active" : "",
            },
          ],
        });
      }
    });

    listSteps.map((ls) => {
      listItems.push({
        title: ls.groupTitle,
        children: ls.items,
        style: "largeDropdown",
      });
    });

    listItems.push({
      title: "Final report",
      action: () => setCurrentTab(1),
      style: currentTab === 1 ? "active" : "",
    });

    const sidebarContent = [
      {
        items: listItems,
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, [currentTab, currentStep]);

    if (!dim) return null;

    return (
      <div className={styles.evaluationSteps}>
        {steps.map((step, index) => (
          <React.Fragment key={"EvStep-" + step.id}>
            {index === currentStep && (
              <Step
                assessment={assessment}
                dim={step}
                steps={steps}
                setSteps={setSteps}
                assessmentRows={assessmentRows}
                currentStep={currentStep}
                handleStep={handleStep}
                person={person}
                wisgptContextToken={wisgptContextToken}
                viewer={viewer}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                getLeadershipSpanEvaluation={getLeadershipSpanEvaluation}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }
);
