import React, { useState } from "react";
import compose from "./compose";
import Timestamp from "react-timestamp";

import Table from "components/v2/UI/Table";

import UserAvatar from "components/UserAvatar";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";

import Icon from "components/UI/Icon/index";
import Button from "components/UI/Button/index";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { typography } from "style/general";

import Modal from "react-modal";
import CreateAssessment from "./CreateAssessment";
import Popover from "components/v2/UI/Popover";
import Chip from "components/v2/UI/Chip";
import { printoutRoute } from "client/authRoutes";

export default compose(
  ({
    data: { assessments },
    deleteAssessment,
    match: {
      params: { account_id },
    },
  }) => {
    const [createAssessment, setCreateAssessment] = useState(false);

    const popoverActions = [
      {
        name: "Print pdf",
        icon: "Print",
        action: (assessment) => {
          window.open(
            `${printoutRoute}/printout/workspace/${account_id}/assessment/${assessment.id}/${assessment.person.first_name}_${assessment.person.last_name}_assessment.pdf`,
            "_blank",
            "noopener,noreferrer"
          );
        },
      },
      {
        name: "Delete Assesment",
        icon: "Delete",
        action: (assessment) =>
          deleteAssessment({
            variables: {
              assessment_id: assessment.id,
            },
          }),
      },
    ];

    const statuses = {
      NOT_STARTED: {
        color: "grey",
        text: "NOT STARTED",
      },
      IN_PROGRESS: {
        color: "orange",
        text: "IN PROGRESS",
      },
      COMPLETED: {
        color: "green",
        text: "COMPLETED",
      },
    };

    const columns = React.useMemo(
      () => [
        {
          Header: "Name",
          accessor: "person", // accessor is the "key" in the data
          Cell: ({ row }) => {
            return (
              <a
                href={`/workspace/${account_id}/assessment/${row.original.id}`}
                className={classnames(
                  styles.assessmentGroup__link,
                  "label",
                  "no-border"
                )}
              >
                <div className={styles.assessmentGroup__flexContainer}>
                  <div className={styles.assessmentGroup__flexColumn}>
                    <div className={styles.assessmentGroup__flexRow}>
                      <div>
                        <UserAvatar
                          member={row.original.person}
                          size="verySmall"
                        />
                      </div>

                      {`${row.original.person.first_name} ${row.original.person.last_name}`}
                    </div>
                  </div>
                </div>
              </a>
            );
          },
        },
        {
          Header: "Scorecard",
          accessor: "scorecard",
          Cell: ({ value }) => {
            return (
              <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                <Icon icon="Clipboard" primary />
                <p
                  style={{
                    ...typography.label,
                    ...typography.bold,
                    ...typography.size14,
                  }}
                >
                  {value.title}
                </p>
              </div>
            );
          },
        },

        {
          Header: "Date",
          accessor: "created_at",
          Cell: ({ value }) => {
            const date = new Date(parseInt(value));
            return (
              <p style={typography.size14}>
                <Timestamp
                  date={date}
                  autoUpdate
                  options={{ format: "date" }}
                />
              </p>
            );
          },
        },
        // {
        //   Header: "Status",
        //   accessor: "status",
        //   Cell: ({ value, row }) => {
        //     console.log({ row });
        //     return (
        //       <Chip color={statuses["IN_PROGRESS"].color} variant="light">
        //         {statuses["IN_PROGRESS"].text}
        //       </Chip>
        //     );
        //   },
        // },
        {
          Header: "Actions",
          accessor: "actions",
          Cell: ({ row }) => (
            <div className={styles.cellWrapper}>
              <Popover
                popOverActions={popoverActions}
                popLeft={true}
                item={row.original}
              />
            </div>
          ),
        },
      ],
      []
    );

    return (
      <SidebarWrapper>
        <SidebarHeader title={"Assessments"}>
          <Button
            bStyle={["primary", "verySmall"]}
            onClick={() => setCreateAssessment(true)}
          >
            <Icon icon="Plus" marginRight />
            New assessment
          </Button>
        </SidebarHeader>
        <Table columns={columns} data={assessments} />
        {createAssessment && (
          <Modal
            isOpen={createAssessment}
            onRequestClose={() => setCreateAssessment(false)}
            shouldCloseOnOverlayClick
          >
            <Button
              bStyle="close small"
              type="button"
              onClick={() => setCreateAssessment(false)}
            >
              <i className="fal fa-times" />
            </Button>
            <CreateAssessment setCreateAssessment={setCreateAssessment} />
          </Modal>
        )}
      </SidebarWrapper>
    );
  }
);
