import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import UserAvatar from "components/UserAvatar";
import EvaluationSteps from "./EvaluationSteps";
import OverviewSection from "./OverviewSection";
import { useSidebar } from "utils/sidebarProvider";
import { printoutRoute } from "client/authRoutes";

import compose from "./compose";

import styles from "./Interviewguide.module.scss";

export const iconsAndColors = {
  Experience: {
    icon: "Businessman",
    color: "primary",
  },
  "Leadership Span": {
    icon: "Extraversion",
    color: "yellow",
  },
  "Growth Potential": {
    icon: "Plant",
    color: "green",
  },
  "Potential Realization": {
    icon: "Muscle",
    color: "purple",
  },
};

export default compose(
  ({
    ASSESSMENT_QUERY: { assessment },
    EVALUATIONS_QUERY: { evaluations },
    NOTES_QUERY: { assessmentNotes },
    viewer,
    currentTab,
    setCurrentTab,
    list,

    match: {
      params: { account_id },
    },
  }) => {
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const listItems = [];

    list.map((item, index) => {
      listItems.push({
        title: item,
        action: () => setCurrentTab(index),
        style: currentTab === index ? "active" : "",
      });
    });

    const sidebarContent = [
      {
        items: listItems,
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, [currentTab]);

    return (
      <>
        <div className={styles.main}>
          <SidebarWrapper noFooter wide>
            <SidebarHeader title={`${assessment.scorecard.title}`}>
              {currentTab === 1 && (
                <a
                  href={`${printoutRoute}/printout/workspace/${account_id}/assessment/${assessment.id}/${assessment.person.first_name}_${assessment.person.last_name}_assessment.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="button" bStyle={["secondary", "verySmall"]}>
                    <Icon icon="Print" marginRight />
                    Print PDF
                  </Button>
                </a>
              )}
              <Link to={`/workspace/${account_id}/assessments`}>
                <Button bStyle={["none"]}>
                  <span className={styles.close}>
                    <Icon icon="Close" />
                  </span>
                </Button>
              </Link>
            </SidebarHeader>
            <div className={styles.content}>
              {currentTab === 0 && (
                <EvaluationSteps
                  steps={assessment.scorecard.assessment_rows}
                  assessmentRows={assessment.scorecard.assessment_rows}
                  person={assessment.person}
                  evaluations={evaluations}
                  notes={assessmentNotes}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  viewer={viewer}
                  setContent={setContent}
                  assessment={assessment}
                />
              )}
              {currentTab === 1 && (
                <>
                  <div className={styles.head}>
                    <div className={styles.row}>
                      <h2 className={styles.title}>
                        <span className={styles.icon}>
                          <Icon icon="Clipboard" />
                        </span>
                        Final report
                      </h2>
                      <div
                        className={classNames(
                          styles.candidate,
                          styles.marginLeft
                        )}
                      >
                        <UserAvatar member={assessment.person} size="medium" />
                        <div className={styles.right}>
                          <h4 className={styles.name}>
                            {assessment.person.name}
                          </h4>
                          <p className={styles.email}>
                            {assessment.person.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <OverviewSection
                    evaluations={evaluations}
                    person={assessment.person}
                    viewer={viewer}
                    assessmentScorecard={assessment.scorecard}
                    assessmentRows={assessment.scorecard.assessment_rows}
                    notes={assessmentNotes}
                    assessment={assessment}
                  />
                </>
              )}
            </div>
          </SidebarWrapper>
        </div>
      </>
    );
  }
);
