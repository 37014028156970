import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import Modal from "react-modal";
import classNames from "classnames";
import QueryString from "query-string";
import { Link } from "react-router-dom";
import allowedRoutesWhileAccountIsSuspended from "utils/allowedRoutesWhileAccountIsSuspended";
import { ReactComponent as WisnioLogo } from "images/icons/Wisnio.svg";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import UserAvatar from "components/UserAvatar";
import NewAccount from "components/Modals/NewAccount";
import AvatarDropdown from "./AvatarDropdown";
import Notifications from "./Notifications";
import AccountSuspended from "./AccountSuspended";
import SupportDropdown from "./SupportDropdown";
import OrgInvitation from "components/Modals/OrgInvitation";
import { CLOSE_INVITE } from "store/actionTypes";
import Pane from "./Pane";
import compose from "./compose";
import { matchPath } from "react-router-dom";

import styles from "./Sidebar.module.scss";

export default compose(
  ({
    data: { invites },
    inviteStore,
    dispatch,
    route,
    history,
    viewer,
    selectedOrg,
    account,
    accountModal,
    setAccountModal,
    children,
    handleSwitchAccount,
    open,
    setOpen,
    collapsed,
    setCollapsed,
  }) => {
    const whitelabel = document.whitelabel === "custom" ? true : false;

    if (!children) return null;
    const [pageTitle, setPageTitle] = useState(null);
    const [headerHeight, setHeaderHeight] = useState(50);

    useEffect(() => {
      setOpen(false);

      const routes = children.map((child) =>
        Object.assign({}, child.props, { strict: true })
      );

      routes.forEach((routerObj) => {
        const pathMatch = matchPath(route.current, {
          path: routerObj.path,
          exact: true,
          strict: true,
        });
        if (pathMatch && routerObj.sidebar) {
          setPageTitle(routerObj.sidebar);
        }
      });

      const mobileHeader = document.querySelector(".mobileHeader");
      if (mobileHeader) {
        setHeaderHeight(mobileHeader.offsetHeight);
      }
    }, [route]);

    useEffect(() => {
      if (invites.length > 0) {
        document.getElementById("notificationsButton").click();
      }
    }, [invites]);

    useEffect(() => {
      const { create } = QueryString.parse(history.location.search);
      if (create && create === "newAccount") {
        setAccountModal(true);
      }
    }, [history.location.search]);

    const mouseEnter = (page) => {
      (pageTitle === "Dashboard" || pageTitle === "Settings") &&
        document.getElementsByClassName("Label-" + page)[0] &&
        document
          .getElementsByClassName("Label-" + page)[0]
          .classList.add(styles.labelActive);
    };

    const mouseLeave = (page) => {
      (pageTitle === "Dashboard" || pageTitle === "Settings") &&
        document.getElementsByClassName("Label-" + page)[0] &&
        document
          .getElementsByClassName("Label-" + page)[0]
          .classList.remove(styles.labelActive);
    };

    return (
      <div
        id="sidebarWrapper"
        className={classNames(
          styles.wrap,
          collapsed && styles.sidebarCollapsed,
          collapsed && "collapsed"
        )}
      >
        {window.innerWidth < 960 &&
          !document.body.classList.contains("forceDesktop") && (
            <div className={classNames(styles.mobileHeader, "mobileHeader")}>
              {whitelabel ? (
                <div className={classNames(styles.logo, styles.mobile)}>
                  <div className={styles.clientLogo} />
                </div>
              ) : (
                <div className={classNames(styles.logo, styles.mobile)}>
                  <Link to="/dashboard">
                    <WisnioLogo />
                  </Link>
                </div>
              )}
              <div
                className={classNames(styles.burgerMenu, open && styles.open)}
              >
                <Button
                  bStyle={["none"]}
                  onClick={() => {
                    if (open) {
                      setOpen(false);
                    } else {
                      setOpen(true);
                    }
                  }}
                >
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                </Button>
              </div>
            </div>
          )}
        <div
          id="sidebar"
          className={classNames(
            styles.main,
            "sidebar",
            open && styles.open,
            window.innerWidth < 960 &&
              !document.body.classList.contains("forceDesktop") &&
              styles.mobile
          )}
        >
          {open && (
            <div
              style={{ top: headerHeight + "px" }}
              className={styles.overLay}
            ></div>
          )}
          {window.innerWidth > 960 && (
            <div
              className={classNames(
                styles.collapseBtn,
                collapsed && styles.collapsed
              )}
            >
              <Button
                bStyle={["none"]}
                onClick={() => {
                  if (collapsed) {
                    setCollapsed(false);
                  } else {
                    setCollapsed(true);
                  }
                }}
              >
                <Icon icon="Chevron" />
              </Button>
            </div>
          )}
          {window.innerWidth > 960 && whitelabel && (
            <div className={styles.whitelabelLogo}>
              <div className={styles.clientLogo} />
            </div>
          )}
          <div className={styles.left}>
            <div
              className={classNames(
                styles.top,
                whitelabel && styles.whitelabel
              )}
            >
              <div className={styles.logo}>
                <Link to="/dashboard">
                  <WisnioLogo />
                </Link>
              </div>
              <div className={styles.section}>
                <div
                  className={classNames(
                    styles.button,
                    pageTitle === "Dashboard" && styles.active
                  )}
                >
                  <Link to={"/"}>
                    <Button bStyle={["none"]}>
                      <Icon icon="House" />
                    </Button>
                  </Link>
                  {(pageTitle !== "Dashboard" || collapsed) && (
                    <div className={styles.tooltip}>
                      <p>Dashboard</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.section}>
                <div
                  className={classNames(
                    styles.button,
                    "People",
                    pageTitle === "Members" && styles.active
                  )}
                  onMouseEnter={() => mouseEnter("People")}
                  onMouseLeave={() => mouseLeave("People")}
                >
                  <Link to={"/org/" + selectedOrg.id + "/members"}>
                    <Button bStyle={["none"]}>
                      <Icon icon="User" />
                    </Button>
                  </Link>
                  {(pageTitle !== "Dashboard" || collapsed) && (
                    <div className={styles.tooltip}>
                      <p>People</p>
                    </div>
                  )}
                </div>
                <div
                  className={classNames(
                    styles.button,
                    "Teams",
                    ["Teams", "Team"].includes(pageTitle) && styles.active
                  )}
                  onMouseEnter={() => mouseEnter("Teams")}
                  onMouseLeave={() => mouseLeave("Teams")}
                >
                  <Link to={"/teams"}>
                    <Button bStyle={["none"]}>
                      <Icon icon="Users" />
                    </Button>
                  </Link>
                  {(pageTitle !== "Dashboard" || collapsed) && (
                    <div className={styles.tooltip}>
                      <p>Teams</p>
                    </div>
                  )}
                </div>
                <div
                  className={classNames(
                    styles.button,
                    "Hiring",
                    ["Position", "Hiring"].includes(pageTitle) && styles.active
                  )}
                  onMouseEnter={() => mouseEnter("Hiring")}
                  onMouseLeave={() => mouseLeave("Hiring")}
                >
                  <Link to={"/hiring"}>
                    <Button bStyle={["none"]}>
                      <span className={styles.kanbanIcon}>
                        <Icon icon="Kanban" />
                      </span>
                    </Button>
                  </Link>
                  {(pageTitle !== "Dashboard" || collapsed) && (
                    <div className={styles.tooltip}>
                      <p>Hiring</p>
                    </div>
                  )}
                </div>
                <div
                  className={classNames(
                    styles.button,
                    "Scorecards",
                    ["Scorecards"].includes(pageTitle) && styles.active
                  )}
                  onMouseEnter={() => mouseEnter("Scorecards")}
                  onMouseLeave={() => mouseLeave("Scorecards")}
                >
                  <Link to={"/" + selectedOrg.id + "/scorecards"}>
                    <Button bStyle={["none"]}>
                      <span className={styles.clipboard}>
                        <Icon icon="Clipboard" />
                      </span>
                    </Button>
                  </Link>
                  {(pageTitle !== "Dashboard" || collapsed) && (
                    <div className={styles.tooltip}>
                      <p>Scorecards</p>
                    </div>
                  )}
                </div>
                {account.assessments && (
                  <div
                    className={classNames(
                      styles.button,
                      "Assessments",
                      ["Assessments"].includes(pageTitle) && styles.active
                    )}
                    onMouseEnter={() => mouseEnter("Assessments")}
                    onMouseLeave={() => mouseLeave("Assessments")}
                  >
                    <Link to={`/workspace/${account.id}/assessments`}>
                      <Button bStyle={["none"]}>
                        <Icon icon="ToolsAssesment" />
                      </Button>
                    </Link>
                    {(pageTitle !== "Dashboard" || collapsed) && (
                      <div className={styles.tooltip}>
                        <p>Assessments</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.section}>
                <div
                  className={classNames(
                    styles.button,
                    styles.learningIcon,
                    "Learning Centre"
                  )}
                  onMouseEnter={() => mouseEnter("Learning Centre")}
                  onMouseLeave={() => mouseLeave("Learning Centre")}
                >
                  <Button
                    bStyle={["none"]}
                    onClick={() =>
                      window.open("https://support.wisnio.com/en/", "_blank")
                    }
                  >
                    <Icon icon="Logo" />
                  </Button>
                  {(pageTitle !== "Dashboard" || collapsed) && (
                    <div className={styles.tooltip}>
                      <p>Learning Centre</p>
                    </div>
                  )}
                </div>
                <div className={styles.help}>
                  <div className="dropdownWrapper left">
                    <Popup
                      trigger={
                        <div
                          id="supportButton"
                          className={classNames(styles.button, "Support")}
                          onMouseEnter={() => mouseEnter("Support")}
                          onMouseLeave={() => mouseLeave("Support")}
                        >
                          <Button bStyle={["none"]}>
                            <Icon icon="Mail" />
                          </Button>
                          {(pageTitle !== "Dashboard" || collapsed) && (
                            <div className={styles.tooltip}>
                              <p>Support</p>
                            </div>
                          )}
                        </div>
                      }
                      position="right center"
                      arrow={false}
                    >
                      <SupportDropdown />
                    </Popup>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.section}>
                <div
                  id="myProfile"
                  className={classNames(
                    styles.button,
                    "Personal",
                    ["Personal"].includes(pageTitle) && styles.active
                  )}
                  onMouseEnter={() => mouseEnter("Personal")}
                  onMouseLeave={() => mouseLeave("Personal")}
                >
                  <Link to={"/personal"}>
                    <Button bStyle={["none"]}>
                      <Icon icon="User" />
                    </Button>
                  </Link>
                  {(pageTitle !== "Dashboard" || collapsed) && (
                    <div className={styles.tooltip}>
                      <p>My profile</p>
                    </div>
                  )}
                </div>
                <div className={classNames(styles.notifications)}>
                  <div className="dropdownWrapper left">
                    <Popup
                      trigger={
                        <div
                          id="notificationsButton"
                          className={classNames(
                            styles.notificationBell,
                            "Notifications"
                          )}
                          onMouseEnter={() => mouseEnter("Notifications")}
                          onMouseLeave={() => mouseLeave("Notifications")}
                        >
                          <Button bStyle={["none"]}>
                            <Icon icon="Bell" />
                            {invites.length > 0 && (
                              <span className={styles.bellDot}></span>
                            )}
                          </Button>
                        </div>
                      }
                      position="right bottom"
                      arrow={false}
                    >
                      <Notifications
                        invites={invites}
                        dispatch={dispatch}
                        inviteStore={inviteStore}
                      />
                    </Popup>
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.avatarDropdown}>
                  <Button bStyle={["none"]}>
                    <div className="dropdownWrapper left" id="dropdownMenu">
                      <Popup
                        keepTooltipInside="body"
                        trigger={
                          <div className={styles.avatarWrapper}>
                            <UserAvatar member={viewer} size="small" pointer />
                          </div>
                        }
                        position="right bottom"
                        arrow={false}
                      >
                        {(close) => (
                          <AvatarDropdown
                            viewer={viewer}
                            account={account}
                            selectedOrg={selectedOrg}
                            close={close}
                            setAccountModal={setAccountModal}
                            handleSwitchAccount={handleSwitchAccount}
                          />
                        )}
                      </Popup>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Pane
            collapsed={collapsed}
            whitelabel={whitelabel}
            account={account}
            pageTitle={pageTitle}
            viewer={viewer}
            selectedOrg={selectedOrg}
            setAccountModal={setAccountModal}
            invites={invites}
            handleSwitchAccount={handleSwitchAccount}
            history={history}
          />
        </div>
        {children}
        {account.frozen &&
          !allowedRoutesWhileAccountIsSuspended.includes(
            route.current.split("/")[1]
          ) && <AccountSuspended account={account} />}
        <Modal
          isOpen={!!accountModal}
          onRequestClose={() => setAccountModal(false)}
          shouldCloseOnOverlayClick
          className={classNames(styles.modal, "create")}
        >
          <NewAccount updateModal={setAccountModal} />
        </Modal>
        <Modal
          isOpen={inviteStore.isOpen}
          onRequestClose={() => dispatch({ type: CLOSE_INVITE })}
          shouldCloseOnOverlayClick
          className="create"
        >
          <Button
            bStyle="close"
            type="button"
            onClick={() => dispatch({ type: CLOSE_INVITE })}
          >
            <i className="fal fa-times" />
          </Button>
          <OrgInvitation invite={inviteStore.invite} />
        </Modal>
      </div>
    );
  }
);
