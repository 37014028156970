import React, { useEffect } from "react";
import UserAvatar from "components/UserAvatar";
import compose from "./compose";

import styles from "./SearchTalentPool.module.scss";

export default compose(
  ({
    handleValidateAndUpdateList,
    data: { loading, searchTalentPool: results },
    error,
    setLoading,
  }) => {
    useEffect(() => {
      setLoading(loading);
    }, [loading]);

    if (!results || loading || results.length < 1 || error) return null;

    return (
      <ul className={styles.searchResults}>
        {results.map((res) => (
          <li
            className={styles.searchWrapper}
            key={`${res.type}:${res.id}`}
            onClick={() => handleValidateAndUpdateList(res)}
          >
            <div className={styles.avatarWrapper}>
              <UserAvatar
                member={res}
                isInvite={res.type === "invite"}
                size="small"
              />
            </div>
            <div className={styles.info}>
              <p className={styles.userName}>
                {res.name ? res.name : res.email}
              </p>
              {res.name && <p className={styles.userEmail}>{res.email}</p>}
            </div>
          </li>
        ))}
      </ul>
    );
  }
);
