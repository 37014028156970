import { iconsAndColors } from "..";

const createSteps = ({ evaluations, viewer, assessmentRows, notes }) => {
  const steps = [];
  assessmentRows.map((row) => {
    const evaluation = evaluations.find(
      (ev) => ev.row_id === row.id && ev.created_by.id === viewer.id
    )
      ? evaluations.find(
          (ev) => ev.row_id === row.id && ev.created_by.id === viewer.id
        ).evaluation
      : null;

    row.evaluation = evaluation;
    row.notes = notes.filter((note) => note.row_id === row.id);
    row.icon = iconsAndColors[row.group_title]
      ? iconsAndColors[row.group_title].icon
      : "Flag";
    row.color = iconsAndColors[row.group_title]
      ? iconsAndColors[row.group_title].color
      : "grey";

    steps.push(row);
  });

  return steps;
};

export default createSteps;
