import React, { useState, useEffect } from "react";
import InputGroup from "components/v2/UI/InputGroup";
import Field from "components/UI/Field";
import styles from "./CreateAssessment.module.scss";
import compose from "./compose";
import SearchTalentPool from "./SearchTalentPool";
import UserAvatar from "components/UserAvatar";
import ScoreCard from "components/v2/UI/ScoreCard/ScoreCard/index";
import classNames from "classnames";
import Button from "components/UI/Button";

export default compose(
  ({
    data: { assessmentScorecards },
    match: {
      params: { account_id },
    },
    createAssessment,
    setCreateAssessment,
  }) => {
    const [searchValue, setSearchValue] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [selectedScoreCard, setSelectedScorecard] = useState(null);

    return (
      <div className={styles.wrapper}>
        <h2>Create assessment</h2>

        {!user && (
          <InputGroup label="Who is being assessed?">
            <Field
              placeholder="Name or email"
              type="input"
              // value={}
              onChange={(event) => {
                event.preventDefault();
                setSearchValue(event.target.value);
              }}
              white
              noBorder
            />
            {searchValue && loading && (
              <div className={styles.loading}>Searching...</div>
            )}
            {searchValue && searchValue !== "" && (
              <SearchTalentPool
                setError={setError}
                setLoading={setLoading}
                handleValidateAndUpdateList={(user) => {
                  setUser(user);
                }}
                where={{
                  invites: false,
                  people: false,
                  searchValue: searchValue,
                  account_id: parseInt(account_id, 10),
                }}
              />
            )}
          </InputGroup>
        )}
        {user && (
          <div className={styles.user}>
            <UserAvatar member={user} size="medium" />
            <div className={styles.right}>
              <h4 className={styles.name}>{user.name}</h4>
              <p className={styles.email}>{user.email}</p>
            </div>
          </div>
        )}
        <div className={styles.scorecards}>
          {assessmentScorecards.map((scorecard) => (
            <div
              key={scorecard.id}
              className={classNames(
                styles.scorecardWrapper,
                selectedScoreCard &&
                  selectedScoreCard.id === scorecard.id &&
                  styles.selected
              )}
            >
              <ScoreCard
                template={scorecard}
                handleClick={setSelectedScorecard}
              />
            </div>
          ))}
        </div>

        {user && selectedScoreCard && (
          <div className={styles.submit}>
            <Button
              bStyle={["primary"]}
              onClick={() => {
                createAssessment({
                  variables: {
                    scorecard_id: selectedScoreCard.id,
                    user_id: user.id,
                    account_id: parseInt(account_id, 10),
                  },
                });
                setCreateAssessment(false);
              }}
            >
              Create assessment
            </Button>
          </div>
        )}
      </div>
    );
  }
);
