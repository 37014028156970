import { iconsAndColors } from "..";

export default ({ person, evaluations, notes, viewer, assessmentRows }) => {
  const list = [];

  assessmentRows.map((row) => {
    const index = list.findIndex((al) => al.groupTitle === row.group_title);

    const evaluation = evaluations
      .filter((ev) => ev.row_id === row.id && ev.created_by.id === viewer.id)
      .reduce((a, b) => a + b.evaluation, 0);

    const totalEvaluation =
      evaluations
        .filter((ev) => ev.row_id === row.id)
        .reduce((a, b) => a + b.evaluation, 0) /
      evaluations.filter((ev) => ev.row_id === row.id).length;

    const evaluationInfo = evaluations
      .filter((ev) => ev.row_id === row.id)
      .map((e) => ({
        item_id: e.id,
        created_by: e.created_by,
        created_at: e.created_at,
        evaluation: e.evaluation,
        total: totalEvaluation,
        type: e.type,
        comments: notes
          .filter((note) => note.row_id === row.id)
          .map((note) => ({
            ...note,
            createdBy: note.created_by,
            comment: note.note,
          })),
      }));

    if (index !== -1) {
      list[index].items = [
        ...list[index].items,
        {
          id: row.id,
          title: row.title,
          description: row.description,
          evaluation: evaluation,
          totalEvaluation: Math.round(totalEvaluation, 10),
          evaluationInfo: evaluationInfo,
          comments: notes
            .filter((note) => note.row_id === row.id)
            .map((note) => ({
              ...note,
              createdBy: note.created_by,
              comment: note.note,
            })),
        },
      ];
    } else {
      list.push({
        groupTitle: row.group_title,
        color: iconsAndColors[row.group_title]
          ? iconsAndColors[row.group_title].color
          : "grey",
        items: [
          {
            id: row.id,
            title: row.title,
            description: row.description,
            evaluation: evaluation,
            totalEvaluation: Math.round(totalEvaluation, 10),
            evaluationInfo: evaluationInfo,
            comments: notes
              .filter((note) => note.row_id === row.id)
              .map((note) => ({
                ...note,
                createdBy: note.created_by,
                comment: note.note,
              })),
          },
        ],
      });
    }
  });

  return list;
};
