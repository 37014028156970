import React from "react";
import { renderComponent, compose, withState, branch } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import scrollToTop from "utils/scrollToTop";
import FullPage from "components/Loaders/FullPage";
import withStore from "utils/withStore";
import { loader } from "graphql.macro";

const ASSESSMENT = loader("./assessment.graphql");
const EVALUATIONS = loader("./evaluations.graphql");
const NOTES = loader("./notes.graphql");

export default compose(
  withRouter,
  withStore("viewer", "selectedOrg"),
  withState("currentTab", "setCurrentTab", 0),
  withState("selectedEvaluators", "setSelectedEvaluators", []),
  withState("list", "setList", ["Evaluation", "Final report"]),
  withState("openModal", "updateModal", null),
  withState("overlayClose", "setOverlayClose", true),
  graphql(ASSESSMENT, {
    name: "ASSESSMENT_QUERY",
    options: ({
      match: {
        params: { id },
      },
    }) => ({
      variables: {
        id: parseInt(id, 10),
      },
    }),
  }),
  graphql(EVALUATIONS, {
    name: "EVALUATIONS_QUERY",
    options: ({
      match: {
        params: { id },
      },
    }) => ({
      variables: {
        assessment_id: parseInt(id, 10),
      },
    }),
  }),
  graphql(NOTES, {
    name: "NOTES_QUERY",
    options: ({
      match: {
        params: { id },
      },
    }) => ({
      variables: {
        assessment_id: parseInt(id, 10),
      },
    }),
  }),
  branch(
    (props) => {
      const assessmentLoading =
        props.ASSESSMENT_QUERY && props.ASSESSMENT_QUERY.loading;
      const evaluationsLoading =
        props.EVALUATIONS_QUERY && props.EVALUATIONS_QUERY.loading;

      const notesLoading = props.NOTES_QUERY && props.NOTES_QUERY.loading;
      const condition = assessmentLoading || evaluationsLoading || notesLoading;

      return condition;
    },
    renderComponent(() => <FullPage />)
  ),
  scrollToTop
);
